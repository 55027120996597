import React from 'react';
import { useState, useEffect,useMemo } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { moment } from 'moment';
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { createCheckIn,getCheckInNumber } from '../../services/CheckInServices';
import { getAllPlants } from '../../services/PlantServices';
import{getAllDeliveryChallanForCheckIn} from '../../services/DeliveryChallanServices';
import { getCheckOutForCHeckIn } from '../../services/CheckOutServices';
import { getCustomerMainDetails } from '../../services/CustomerMainServices';
import { getCustomerSiteDetails } from '../../services/CustomerSiteServices';
import { getDeliveryChallanDetails } from '../../services/DeliveryChallanServices';
import { showBillingPop, showCustomerSitePop, showDeliveryChallanPop } from './CheckInUtils';
import SummaryIcon from '../../components/SummaryIcon';
import QuestionIcon from '../../components/QuestionIcon';
import { getDisplayDate } from '../../utils/DateUtils';
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import NumberSetting from '../../components/NumberSetting';
import { AccessAlarm } from '@mui/icons-material';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import ReactTablePopUpSubmitWrapper from "../../components/ReactTablePopUpSubmitWrapper";

function CheckIn() {
  const [cookies] = useCookies(['myToken']);
  const [category] = useCookies(['myCategory']);
  const [superuser] = useCookies(['mySuperuser']);
  const [inputs, setInputs] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [plant, setPlant] = React.useState([]);
  const [plants,setPlants] =React.useState([]);
  const [deliverychallans,setDeliverychallans] =React.useState([]);
  const [checkouts,setCheckouts] =React.useState([]);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [error, setError] = useState({});
  const [allcheckout,setAllCheckOut]=React.useState([]);
  const [alldeliverychallan,setAllDeliveryChallan]=React.useState([]);
  const [deliverychallan,setDeliveryChallan]=React.useState([]);
  const[selectedcheckout,setSelectedDC] = useState({});
  const [isLoading, setIsLoading] = useState(true); 
  const [selected_checkout,setSelectedCheckout] = useState({});
  const[selectedWorkSchedule,setSelectedWorkSchedule] = useState({});
  const[balancestatus,setBalanceStatus]= useState([]);
  const[dcstatus,setDcStatus]= useState([]);
  const initial_table_data = {
    total_pages: 0,
    records: [],
  };
  const [data, setData] = useState(initial_table_data);
  const [showModal, setShowModal] = useState(false);

  React.useEffect(() => {
    setInputs(values => ({...values,
        ['checkin_date']: getCurrentDate(),
        ['checkin_time']: getCurrentTime(),
        // ['weighment_slip_date']: getCurrentDate(),
        // ['weighment_slip_time']: getCurrentTime(),
        // ['checkout_date']: getCurrentDate(),
    }))
  }, []);

  React.useEffect(() => {
    getAllPlants(cookies)
    .then (
        plantList => {
          console.log(JSON.stringify(plantList)+"plantList")
            const plant = plantList.plant_list.filter(obj => obj.status).map(
                plant => {
                    return { value: plant.id, label: plant.plant_alias }
                }
            );
            setPlants(plant);
        }
    )
  }, []);
      
  const navigate = useNavigate();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'tare_weight' || name === 'gross_weight') {
      var tare_weight = document.getElementById('tare_weight').value;
      var gross_weight = document.getElementById('gross_weight').value;     
      if((tare_weight === '') || (tare_weight === NaN) ){
          tare_weight = 0;
      }
      if((gross_weight === '') || (gross_weight === NaN) ){
          gross_weight = 0;
      }
      console.log(tare_weight+"tare_weight")
      console.log(gross_weight+"gross_weight")
      var net_weight = parseFloat(gross_weight) - parseFloat(tare_weight);
      console.log(net_weight+"net_weight")
      setInputs(values => ({...values, ['net_weight']: net_weight})) 
    }
    setInputs(values => ({...values, [name]:value}));
  }
  
  const handleChangePlant = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
      setInputs(values => ({...values, [name]: value}));
      setSelectedCheckout(values => ({['delivery_challan']:{}}));
      if(name === 'plant_id' && value){
        setIsLoading(true);
        getCheckOutForCHeckIn(cookies,value)
          .then(checkoutList => {
            const checkouts = checkoutList.checkout_list.map(
              checkout => {
                  return { value: checkout.id,label: checkout.checkout_no}
              }
          );
          setCheckouts(checkouts)
          setAllCheckOut(checkoutList.checkout_list)
 const tableData = checkoutList.checkout_list
              .map(checkout => ({
                  id :checkout.id,
                  checkout_no:checkout.checkout_no,  
                  dc_date: checkout.delivery_challan.delivery_challan_date,  
                  dc_no:checkout.delivery_challan.prefix+checkout.delivery_challan.delivery_challan_no,  
                  dc_time: checkout.delivery_challan.delivery_challan_time, 
                  schedule_no:checkout.delivery_challan.work_schedule.schedule_no, 
                  sales_order_no: checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.prefix+checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.order_no,                      
                  customer_name:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.name, 
                  consignee_name:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.name,
                  project_name:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.project_name,
                  plant:checkout.delivery_challan.work_schedule.plant.alias_name,
                  grade:checkout.delivery_challan.work_schedule.sales_order_detail.product.name,
                  driver_name:checkout.delivery_challan.driver_name,
                  driver_mobile_no:checkout.delivery_challan.driver_mobile_no,
                  dc_quantity:checkout.delivery_challan.dc_qty,
                  vehicle_no:checkout.delivery_challan.vehicle.name?checkout.delivery_challan.vehicle.name:checkout.delivery_challan.customer_vehicle_no
              }));
              setData({
                records: tableData
              });
setIsLoading(false);
          })
        .catch((error) =>
        {
            setIsLoading(false);
          console.log(error.response.data);
          displayError(error.response.data," Failed");
        })
      }
      else
      {
        setInputs({})
      }
      }
    catch (e) {
      displayErrorToast(e);
    }
  }

  const handleChangeOpeningKmDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if ( name === 'ending_km') {
         var opening_km = document.getElementById('opening_km').value;
         var ending_km = document.getElementById('ending_km').value;
         document.getElementById('ending_km').setAttribute('min',opening_km);      
         if((opening_km === '') || (opening_km === NaN) ){
          opening_km = 0;
         }
         if((ending_km === '') || (ending_km === NaN) ){
          ending_km = 0;
         }
         console.log(opening_km+"opening_km")
         console.log(ending_km+"ending_km")
        var total_km = parseInt(ending_km) - parseInt(opening_km);
        console.log(total_km+"total_km")
        setInputs(values => ({...values, ['total_km']: total_km})) 
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };
  const handleChangeOpeningHmDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if ( name === 'ending_hm') {
         var opening_hm = document.getElementById('opening_hm').value;
        var ending_hm = document.getElementById('ending_hm').value;
        document.getElementById('ending_hm').setAttribute('min',opening_hm);     
         if((opening_hm === '') || (opening_hm === NaN) ){
          opening_hm = 0;
         }
         if((ending_hm === '') || (ending_hm === NaN) ){
          ending_hm = 0;
         }
         console.log(opening_hm+"opening_hm")
         console.log(ending_hm+"ending_hm")
        var total_hm = parseInt(ending_hm) - parseInt(opening_hm);
        console.log(total_hm+"total_hm")
        setInputs(values => ({...values, ['total_hm']: total_hm})) 
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };

  // Function to handle changes in the Customer's Status dropdown
  const handleChangeCustomerStatusDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(value === 'accepted'){
            var dc_qty = document.getElementById('dc_qty').value;
            var client_adjust_qty= selected_checkout.delivery_challan.client_adjust_qty;
            console.log ("client_adjust_qty"+client_adjust_qty)
            var is_client_adj_qty_incld_inv= selected_checkout.delivery_challan.is_client_adj_qty_incld_inv;
            console.log(is_client_adj_qty_incld_inv+"is_client_adj_qty_incld_inv");
            if(is_client_adj_qty_incld_inv)
            {
               var accepted_qty= dc_qty;
           }
            else
            {
              var accepted_qty= dc_qty - client_adjust_qty;
            }
          console.log(dc_qty+"dc_qty")
            console.log(accepted_qty+"accepted_qty")
            document.getElementById('accepted_qty').setAttribute('readOnly','readOnly');
            document.getElementById('accepted_qty').style.cursor = "not allowed";
          var balance_qty = parseFloat(dc_qty) - parseFloat(accepted_qty);
          console.log(balance_qty+"balance_qty accepted")
          setInputs(values => ({...values, ['balance_qty']: balance_qty,['accepted_qty']:accepted_qty})) 
          if(balance_qty === 0){
            var balance_status_List= [{ value: "nill", label: "Nill" }]
            setInputs(values => ({...values, ['balance_status']:"nill"}))
             }
            else{
                var balance_status_List= [
                { value: "", label: "choose balance status" },
                { value: "full", label: "Full" },
                { value: "returned", label: "Returned" },
                { value: "wastage", label: "Wastage" },
                { value: "transfer", label: "Transfer"},
                { value: "shortage", label: "Shortage"}]
           }
         setBalanceStatus(balance_status_List)
         var dc_status_List= [
          { value: "", label: "choose customer dc status" },
          { value: "received_with_sign", label: "Received with Sign" },
          { value: "received_without_sign", label: "Received without Sign" },
          { value: "not_received", label: "Not Received" }
         ]
        setDcStatus(dc_status_List)
        }
       
   if(value === 'cancelled')
    {
      var dc_qty = document.getElementById('dc_qty').value;
      document.getElementById('accepted_qty').setAttribute('readOnly','readOnly');
      document.getElementById('accepted_qty').style.cursor = "not allowed";
      var balance_qty = parseFloat(dc_qty) - 0;
      setInputs(values => ({...values, ['balance_qty']: balance_qty,['accepted_qty']:0})) 
      
        var dc_status = ["Cancelled"];
        var balance_status_List= [
          { value: "", label: "choose balance status" },
            { value: "full", label: "Full" },
            { value: "returned", label: "Returned" },
            { value: "wastage", label: "Wastage" },
            { value: "transfer", label: "Transfer"},
            { value: "shortage", label: "Shortage"}]
        setBalanceStatus(balance_status_List);
        setDcStatus([
          { value: "cancelled", label: "Cancelled" }
         ])
         setInputs(values => ({...values, ['cus_dc_status']:"cancelled"}))
 }
    if(value === 'partial_accepted' ){
            var dc_qty = document.getElementById('dc_qty').value;
            var accepted_qty = document.getElementById('accepted_qty');
            document.getElementById('accepted_qty').removeAttribute('readOnly');
            document.getElementById('accepted_qty').style.cursor = "pointer";
            document.getElementById('accepted_qty').setAttribute('max',dc_qty);
            var balance_qty = (parseFloat(dc_qty) - parseFloat(accepted_qty));
            console.log(balance_qty+"balance_qty partial"+(balance_qty).toFixed(2)+"two")
            setInputs(values => ({...values, ['balance_qty']: balance_qty})) 
             var balance_status_List= [
              { value: "", label: "choose balance status" },
                { value: "full", label: "Full" },
                { value: "returned", label: "Returned" },
                { value: "wastage", label: "Wastage" },
                { value: "transfer", label: "Transfer"},
                { value: "shortage", label: "Shortage"}]
            setBalanceStatus(balance_status_List)
            var dc_status_List= [
              { value: "", label: "choose customer dc status" },
              { value: "received_with_sign", label: "Received with Sign" },
              { value: "received_without_sign", label: "Received without Sign" },
              { value: "not_received", label: "Not Received" }
             ]
            setDcStatus(dc_status_List)
            }
    setInputs(values => ({...values, [name]: event.target.value}))
  };

  const handleChangeAcceptedDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values,[name]:event.target.value}))
    
      var dc_qty = document.getElementById('dc_qty').value;
      var accepted_qty = parseFloat(value)
     
      var balance_qty = parseFloat(dc_qty) - parseFloat(accepted_qty);
      console.log(balance_qty+"balance_qty")
      setInputs(values => ({...values, ['balance_qty']: balance_qty.toFixed(2)})) 
   }
   
  const showCustomerDetails = () => {
    if (selected_checkout.id) {
        getCustomerMainDetails(cookies,selected_checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.id)
        .then(selectedCustomer => {
            showBillingPop(selectedCustomer)
        })
        }
    }
    
    const showSiteDetails = () => {
      if (selected_checkout.id) {
          getCustomerSiteDetails(cookies,selected_checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.id)
          .then(consignee => {
            showCustomerSitePop(consignee);
          })
        }
        }
    const showDeliveryChallanDetails = () => {
      if (selected_checkout.id) {
          getDeliveryChallanDetails(cookies,selected_checkout.delivery_challan.id)
          .then(selected_DC => {
            showDeliveryChallanPop(selected_DC);
          })
          }
      }

      const handleChangeCheckOutDetails = (value,event) => {
        // console.log(event.target.value);
        const checkoutId = value;
        const name = 'checkout_no';
    
     if (checkoutId) {
            setInputs(values =>({...values,[name]:checkoutId}));
            const selected_checkout = allcheckout.filter(obj => obj.id == checkoutId)[0];
            console.log(JSON.stringify(selected_checkout)+"selected_checkout");
            setSelectedCheckout(selected_checkout);
            setInputs(values => ({...values, [name]:checkoutId,['weighment_slip_no']:selected_checkout.weighment_slip_no,
        ['weighment_slip_date']:getDisplayDate(selected_checkout.weighment_slip_date),
        ['checkout_date']: getDisplayDate(selected_checkout.checkout_date),
        ['weighment_slip_time']:selected_checkout.weighment_slip_time,
        ['gross_weight']:selected_checkout.gross_weight,
        ['tare_weight']:selected_checkout.tare_weight,
        ['net_weight']:selected_checkout.net_weight}));
        } 
        else {
            setSelectedCheckout({});
            // setInputs({})
            setInputs(values => ({...values, [name]:checkoutId,['weighment_slip_no']:'',
              ['weighment_slip_date']:getCurrentDate(),
              ['checkout_date']:getCurrentDate() ,
              ['weighment_slip_time']:getCurrentTime(),
              ['gross_weight']:0,
              ['tare_weight']:0,
              ['net_weight']:0}));
        }
        setShowModal(false)
        // setInputs(values => ({...values, [name]: event.target.value}))
      } 
       
      const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
            id:inputs.checkout_no,
            weighment_slip_no:inputs.weighment_slip_no,
            weighment_slip_date:getDisplayDate(inputs.weighment_slip_date),
            weighment_slip_time:inputs.weighment_slip_time,
            gross_weight:inputs.gross_weight?parseInt(inputs.gross_weight):0,
            tare_weight:inputs.tare_weight?parseInt(inputs.tare_weight):0,
            net_weight:inputs.net_weight?parseInt(inputs.net_weight):0,
            ending_km:inputs.ending_km?parseInt(inputs.ending_km):0,
            total_km:inputs.total_km?parseInt(inputs.total_km):0,
            ending_hm:inputs.ending_hm?parseInt(inputs.ending_hm):0,
            total_hm:inputs.total_hm?parseInt(inputs.total_hm):0,
            customer_status:inputs.customer_status,
            accepted_qty:parseFloat(inputs.accepted_qty),
            balance_qty:parseFloat(inputs.balance_qty),
            balance_status:inputs.balance_status,
            cus_dc_status:inputs.cus_dc_status
           }
      } 
        
  const handleSubmit = (event) => {
    event.preventDefault()
    try{
  if ((Object.values(error).every(x => !x)) && isSubmitting) {
      Swal.fire({title: (inputs.customer_status === 'cancelled'? 'This Will be Deleted if this Cancelled DC is last?. ' : '') + 'Do you want to save?', 
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
            if (result.isConfirmed) 
            { 
                setIsLoading(true);
                createCheckIn(cookies, constructFormData())
                .then(function (response) {
                    setIsLoading(false);
                    Swal.fire('Saved!', '', 'success') 
                    Reload();
                })
                .catch((error) =>
                {
                    setIsLoading(false);
                  console.log(error.response.data);
                  displayError(error.response.data,"Save Failed");
                })
            }
              else if (result.isDismissed) 
                {    
                Swal.fire('Not saved', '', 'info')  
                }
        });
    }
}
 catch (e) {
        displayErrorToast(e);
    }
}
       
  const Reload = () => {
  window.location.reload();
}  

const Cancel = () => {
  setInputs(() => "")
}

  const view = () => {
    navigate('/CheckInTable')
  } 
  
  const Back = () => {
    navigate('/Home')
  }

  const Help = () => {
                                        setShowModal(true)
  
}
const columns =
        useMemo(
            () => [
                {
                    fieldName: "id",
                    headerName: "ROW_ACTIONS",
                    size:40
                },
                {
                  fieldName: 'checkout_no',
                  headerName: 'Checkout No',
                  size:40
                },
                {
                  fieldName: 'dc_no',
                  headerName: 'Deliverychallan No',
                  size:40
                },
                {
                  fieldName: 'dc_date',
                  headerName: 'Deliverychallan Date',
                  size:50
                },
                {
                  fieldName: 'dc_time',
                  headerName: 'Deliverychallan Time',
                  size:50
                },
                {
                  fieldName: 'schedule_no',
                  headerName: 'Schedule No',
                  size:40
                },
                
                {
                  fieldName: 'plant',
                  headerName: 'Plant',
                  size:50
                },
                {
                  fieldName: 'sales_order_no',
                  headerName: 'SO No',
                  size:40
              },
              {
                fieldName: "customer_name",
                headerName: 'Customer',
                size:180
              },
              {
                  fieldName: "project_name",
                  headerName: 'Site',
                  size:180
              },
              {
                fieldName: "grade",
                headerName: 'Grade',
                size:180
              },
              {
                fieldName: "dc_quantity",
                headerName: 'DC Qty',
                size:180
              },
              {
                fieldName: 'vehicle_no',
                headerName: 'Vehicle No',
                size:50
            },
              {
                fieldName: "driver_name",
                headerName: 'Driver Name',
                size:180
              },
                
                {
                  fieldName: "driver_mobile_no",
                  headerName: 'Driver Mobile No',
                  size:180
                }
                
    ],
    [],);
    const Close = () => {
      setShowModal(false)
    }
    const DeliveryChallan = () => {
      navigate('/DeliveryChallan')
    } 
  
return (
        <>
 <div id="outer-container" className="App" >
 <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
 <ErrorToast/>
 <LoadingOverlay inProgress={inProgress}/>
 <div id="page-wrap">
 <form onSubmit={handleSubmit} >
 <div id="header">
              <h3 className = "text font-weight-bold page-title" >VEHICLE CHECK IN </h3>
            </div>

            <div className="container p-2 ">
            <FloatingControls tableLink="/CheckInTable" onCancel={Cancel} enableCancel={true}/>
            </div>
            <div className="container p-2 ">
   </div>
   <div className="container">
   <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel">
           <h5 className='col-sm-11'><b>DeliveryChallan Details</b></h5><br/><br/>
           
   <Row> 
           <Col xl={6} lg={6} md={6}> 
           <div className="form-row p-2 my-2 border-secondary p-2 mb-3 form-control-panel">   
           <label htmlFor="plant_id" className="form-group col-sm-5 text-right"> Plant Location </label>
               <select id="plant_id"name="plant_id" className="form-control col-sm-6  browser-default custom-select  mandatory-form-control"  required onChange={handleChangePlant} value={inputs.plant_id || ""}>
               <option value="">Select Plant</option>
                           {plants.map((item) => (
                       <option key={item.value} value={item.value}>
                           {item.label}</option>
                   ))}
               </select>
               <br></br> 
               <label htmlFor="checkout_no" className="form-group col-sm-5 text-right">Check Out No.</label>
              <select id="checkout_no" name="checkout_no" disabled={true}  className="form-control col-sm-6"   value={inputs.checkout_no || ""}>
              {/* browser-default custom-select  mandatory-form-control required onChange={handleChangeCheckOutDetails}*/}
                 <option value=""></option>
                 {checkouts.map((item) => (
                         <option key={item.value} value={item.value}>
                         {(item.label)}
                         </option>
                     ))}
               </select> 
               <QuestionIcon onClickHandler={Help}/>
               <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                    <div className="modal-content">
                        <div className="modal-body display">
                        <div className="container item-list-table-container">
                                    <ReactTablePopUpSubmitWrapper
                                        title='List of Group Form'
                                        columns={columns}
                                        data={data.records}
                                        onRowSubmit={handleChangeCheckOutDetails}
                                        onClose = {Close}
                                        // onCSVExport={handleCSVExport}
                                        // onPDFExport={handlePDFExport}
                                        isLoading={isLoading}
                                    />
                            </div> 
                        </div>
                    </div>
                    </div>
                  </div>
               {/* <ViewOnlyTextbox 
                name="checkout_date" 
                value={inputs.checkout_date|| ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Check Out Date/Time"
                inputClassName="form-control col-sm-3"
                />  
                 <ViewOnlyTextbox 
                name="checkout_date" 
                value={selected_checkout?.checkout_time|| ""}
                labelClassName="form-group col-sm-5 text-right"
                inputClassName="form-control col-sm-3" 
                />    */}
                 
                <label htmlFor="checkout_date" className="form-group col-sm-5 text-right">Check Out Date/Time</label>
                <input type="date" className="form-control col-sm-3 text-right" name="checkout_date" value={inputs.checkout_date || ""} id="checkout_date" readOnly={true} style={{cursor: "not-allowed"}} />
                <input type="time" id="checkout_time"  name="checkout_time"  value={selected_checkout.checkout_time || ""} className="form-control col-sm-3" readOnly={true} style={{cursor: "not-allowed"}}/> 

                <ViewOnlyTextbox 
                name="vehicle_no" 
                value={selected_checkout?.delivery_challan?.vehicle?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Vehicle No"
                inputClassName="form-control col-sm-6"
                />  
               
                <br></br>
                <ViewOnlyTextbox 
                name="delivery_challan_no" 
                value={(selected_checkout.id ? '('+selected_checkout?.delivery_challan?.prefix +')'+selected_checkout?.delivery_challan?.delivery_challan_no : "")}
                labelClassName="form-group col-sm-5 text-right"
                label="DC No"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showDeliveryChallanDetails}
                />    
              
             <label htmlFor="checkin_date" className="form-group col-sm-5 text-right">Check In Date/Time</label>
              <input type="date" className="form-control col-sm-3" name="checkin_date" value={inputs.checkin_date || ""} id="checkin_date" readOnly={true} style={{ cursor: "not-allowed"}} />
              <input type="time" id="checkin_time"  name="checkin_time"  value={inputs.checkin_time || ""}  readOnly={true}  style={{ cursor: "not-allowed"}} className="form-control col-sm-3"/>

          <br></br>
                           </div>
               </Col>
          
           <Col xl={6} lg={6} md={6}> 
           <div className="form-row p-2 my-2 border-secondary p-2 mb-3 form-control-panel"> 
           <ViewOnlyTextbox 
                name="company" 
                value={selected_checkout?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.company?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Company"
                inputClassName="form-control col-sm-6"
                />
          
               <ViewOnlyTextbox 
                name="billing_name" 
                value={selected_checkout?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.customer?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Billing Name"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showCustomerDetails}
                />    
             
               <ViewOnlyTextbox 
                name="ship_to" 
                value={selected_checkout?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Ship To"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showSiteDetails}
                />  
               
              
                <ViewOnlyTextbox 
                name="project_name" 
                value={selected_checkout?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.project_name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Project/Site Name"
                inputClassName="form-control col-sm-6"
                
                />  
               <ViewOnlyTextbox 
                name="grade" 
                value={selected_checkout?.delivery_challan?.work_schedule?.sales_order_detail.product.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Grade"
                inputClassName="form-control col-sm-6"
                />  

                <ViewOnlyTextbox 
                name="dc_qty" 
                value={selected_checkout?.delivery_challan?.dc_qty || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="DC Qty"
                inputClassName="form-control col-sm-6"
                />  
               </div>
           </Col>
           </Row>
</div>

<Row>
<Col xl={6} lg={6} md={8}>
<div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel  " >
<h5 className='col-sm-11'><b>Weightment Details</b></h5><br/><br/>
{/* <ViewOnlyTextbox 
                name="weighment_slip_no" 
                value={selected_checkout?.weighment_slip_no|| ""}
                labelClassName="form-group col-sm-4 text-right"
                label="Weightment Slip No."
                inputClassName="form-control col-sm-7"
                />   */}

<label htmlFor="weighment_slip_no" className="form-group col-sm-4 text-right">Weightment Slip No.</label>
<input type="text" id="weighment_slip_no"name="weighment_slip_no" onChange={handleChange} value={inputs.weighment_slip_no || ""} className="form-control col-sm-7"/> 
{/* <ViewOnlyTextbox 
                name="weighment_slip_date" 
                value={selected_checkout?.weighment_slip_date|| ""}
                labelClassName="form-group col-sm-4 text-right"
                label="Weightment Date/Time"
                inputClassName="form-control col-sm-3"
                />  
                 <ViewOnlyTextbox 
                name="weighment_slip_time" 
                value={selected_checkout?.weighment_slip_time||""}
                labelClassName="form-group col-sm-4 text-right"
                inputClassName="form-control col-sm-4"
                />   */}

<label htmlFor="weighment_slip_date" className="form-group col-sm-4 text-right">Weightment Date/Time</label>
<input type="date" className="form-control col-sm-3"name="weighment_slip_date" onChange={handleChange} value={inputs.weighment_slip_date || ""} id="weighment_slip_date"  />
<input type="time" id="weighment_slip_time"required name="weighment_slip_time" onChange={handleChange} value={inputs.weighment_slip_time || ""}  className="form-control col-sm-4"/> 
  {/* <ViewOnlyTextbox 
                  name="gross_weight" 
                  value={selected_checkout?.gross_weight|| ""}
                  labelClassName="form-group col-sm-4 text-right"
                  label="Gross Weight"
                  inputClassName="form-control col-sm-7"
                  />   */}
{/* <ViewOnlyTextbox 
                  name="tare_weight" 
                  value={selected_checkout?.tare_weight|| ""}
                  labelClassName="form-group col-sm-4 text-right"
                  label="Tare Weight"
                  inputClassName="form-control col-sm-7"
                  />   */}
{/* <ViewOnlyTextbox 
                  name="net_weight" 
                  value={inputs.net_weight|| 0}
                  labelClassName="form-group col-sm-4 text-right"
                  label="Net Weight"
                  inputClassName="form-control col-sm-7"
                  />   */}
<label htmlFor="gross_weight" className="form-group col-sm-4 text-right">Gross Weight</label>
<input type="number" id="gross_weight" name="gross_weight" onChange={handleChange} value={inputs.gross_weight || 0} className="form-control col-sm-7"/>

<label htmlFor="tare_weight" className="form-group col-sm-4 text-right">Tare Weight</label>
<input type="number"  name="tare_weight" id="tare_weight" onChange={handleChange} value={inputs.tare_weight || 0} className="form-control col-sm-7"/>

<label htmlFor="net_weight" className="form-group col-sm-4 text-right">Net Weight</label>
<input type="number" id="net_weight" name="net_weight" readOnly={true} style={{cursor: "not-allowed"}}  value={inputs.net_weight || 0}className="form-control col-sm-7"/>
</div>
</Col>
<Col xl={6} lg={12} md={12}>
   <Row>
   <Col xl={6} lg={3} md={3}>
   <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
   {/* <ViewOnlyTextbox 
                  name="opening_km" 
                  value={selected_checkout?.opening_km||""}
                  labelClassName="form-group col-sm-5 text-right"
                  label="Opening KM"
                  inputClassName="form-control col-sm-6"
                  />   */}
              <label htmlFor="opening_km" className="form-group col-sm-5 text-right">Opening KM</label>
             <input type="number" className="form-control col-sm-6" id="opening_km" name="opening_km" value={selected_checkout?.opening_km || 0} readOnly={true} style={{ cursor: "not-allowed" }} /><br />
             <label htmlFor="ending_km" className="form-group col-sm-5 text-right">Ending KM</label>
             <input type="number" className="form-control col-sm-6" id="ending_km" name="ending_km" onChange={handleChangeOpeningKmDetails} value={inputs.ending_km || 0} /><br />
             <label htmlFor="total_km" className="form-group col-sm-5 text-right">Total KM</label>
             <input type="number" className="form-control col-sm-6"  readOnly={true} id="total_km" name="total_km"value={inputs.total_km || 0} style={{ cursor: "not-allowed" }} /><br />
           </div>
   </Col>
   <Col xl={6} lg={3} md={3}>
           <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
           {/* <ViewOnlyTextbox name="opening_hm" value={selected_checkout?.opening_hm|| ""} labelClassName="form-group col-sm-5 text-right"
                  label="Opening HM"
                  inputClassName="form-control col-sm-6"
                  />   */}
             <label htmlFor="opening_hm" className="form-group col-sm-5 text-right">Opening HM</label>
            <input  type="number" id="opening_hm" name="opening_hm" className="form-control col-sm-6 "  value={selected_checkout?.opening_hm || 0} readOnly={true} style={{cursor:"not-allowed"}}/>
                        
             <label htmlFor="ending_hm" className="form-group col-sm-5 text-right">Ending HM</label>
             <input type="number" className="form-control col-sm-6" name="ending_hm" id="ending_hm" onChange={handleChangeOpeningHmDetails} value={inputs.ending_hm || 0} /><br />
             <label htmlFor="total_hm" className="form-group col-sm-5 text-right">Total HM</label>
             <input type="number" className="form-control col-sm-6" id="total_hm" name="total_hm"  readOnly={true} value={inputs.total_hm || 0} style={{ cursor: "not-allowed" }} /><br />
             </div>
   </Col>
   </Row>
   <Row>
   <Col xl={12} lg={6} md={6}>
   <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
   <h5 className='col-sm-11'><b>CheckIn Status Details</b></h5><br/><br/>
             <label htmlFor="customer_status" className="form-group col-sm-4 text-right">Customer's Status</label>
              <select id="customer_status" name="customer_status" className="form-control col-sm-7  browser-default custom-select mandatory-form-control" required onChange={handleChangeCustomerStatusDetails} value={inputs.customer_status || ""}>
                 <option value="">Select</option>
                       <option value="accepted">Accepted</option>
                       <option value="partial_accepted">Partial Accepted</option>
                       <option value="cancelled">Cancelled</option>
               </select><br />

               <label htmlFor="accepted_qty" className="form-group col-sm-4 text-right">Accepted Qty</label>
               <input type="number" className="form-control col-sm-7 mandatory-form-control" required id="accepted_qty" name="accepted_qty" onChange={handleChangeAcceptedDetails} value={inputs.accepted_qty || 0 } readOnly={inputs.mode === "accepted"} style={{ cursor: "not-allowed" }} /><br />
             
             <label htmlFor="balance_qty" className="form-group col-sm-4 text-right">Balance Qty</label>
               <input type="number" className="form-control col-sm-7" id="balance_qty" name="balance_qty" value={inputs.balance_qty || 0 }readOnly={true} style={{ cursor: "not-allowed" }} /><br />

             <label htmlFor="balance_status" className="form-group col-sm-4 text-right">Balance Status</label>
               <select id="balance_status" name="balance_status" className="form-control col-sm-7  browser-default custom-select mandatory-form-control" required onChange={handleChange} value={inputs.balance_status || ""}>
                 {balancestatus.map((item) => (
                         <option key={item.value} value={item.value}>
                         {(item.label)}
                         </option>
                     ))}
               </select><br />

               <label htmlFor="cus_dc_status" className="form-group col-sm-4 text-right">DC Status</label>
               <select id="cus_dc_status" name="cus_dc_status" className="form-control col-sm-7  browser-default custom-select mandatory-form-control" required onChange={handleChange}  value={inputs.cus_dc_status || ""}>
               {dcstatus.map((item) => (
                         <option key={item.value} value={item.value}>
                         {(item.label)}
                         </option>
                     ))}
               </select><br/>
           </div></Col>
   </Row>
</Col>
</Row>

</div>
              <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                &nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"100px",fontWeight:"bold"}} onClick={DeliveryChallan}>DC</Button>
              </div>
    </form>
    </div>
 </div>
</>
);
}
export default CheckIn;